import editorService from '@/services/ide/editor.service'
import { useIdeStore } from '@/stores/ide.store'
import { TAB_BUTTON_POSITION, TAB_ICONS_NAMES } from '@/utils/ide'
import { IDETABSVIEW } from '@/utils/tabs'

/**
 * Updates the visibility of tabs based on the provided tab name and current view.
 * @param tabname - The name of the tab to update.
 * @param currentView - The current view of the application.
 * @param closeTab - Close state to close the particular tab
 */
const updateTabVisiblity = (tabname: string, currentView: string, closeTab: boolean = false) => {
  const values = useIdeStore().activeTabPosition.activeTab

  if (tabname == TAB_ICONS_NAMES.IO && currentView == IDETABSVIEW.IDE) {
    values.forEach((item) => {
      if (
        item.position == TAB_BUTTON_POSITION.BOTTOM &&
        item.tabName == TAB_ICONS_NAMES.IO &&
        !item.visible
      ) {
        useIdeStore().setActiveTabPosition(TAB_BUTTON_POSITION.BOTTOM, TAB_ICONS_NAMES.IO)
      } else if (
        item.position == TAB_BUTTON_POSITION.RIGHT &&
        item.tabName == TAB_ICONS_NAMES.IO &&
        !item.visible
      ) {
        useIdeStore().setActiveTabPosition(TAB_BUTTON_POSITION.RIGHT, TAB_ICONS_NAMES.IO)
      } else if (
        item.position == TAB_BUTTON_POSITION.MAXIMIZE &&
        item.tabName == TAB_ICONS_NAMES.IO &&
        item.visible
      ) {
        useIdeStore().setActiveTabPosition(
          item.previousPosition as TAB_BUTTON_POSITION,
          TAB_ICONS_NAMES.IO
        )
      }
    })
  } else if (tabname == TAB_ICONS_NAMES.EXTERNAL_LIBRARIES && currentView == IDETABSVIEW.IDE) {
    values.forEach((item) => {
      if (
        item.position == TAB_BUTTON_POSITION.BOTTOM &&
        item.tabName == TAB_ICONS_NAMES.EXTERNAL_LIBRARIES &&
        item.visible
      ) {
        useIdeStore().setActiveTabPosition(
          TAB_BUTTON_POSITION.BOTTOM,
          TAB_ICONS_NAMES.EXTERNAL_LIBRARIES
        )
      } else if (
        item.position == TAB_BUTTON_POSITION.RIGHT &&
        item.tabName == TAB_ICONS_NAMES.EXTERNAL_LIBRARIES &&
        item.visible
      ) {
        useIdeStore().setActiveTabPosition(
          TAB_BUTTON_POSITION.RIGHT,
          TAB_ICONS_NAMES.EXTERNAL_LIBRARIES
        )
      }
    })
  } else if (tabname == TAB_ICONS_NAMES.JDROID && currentView == IDETABSVIEW.IDE) {
    values.forEach((item) => {
      if (
        item.position == TAB_BUTTON_POSITION.BOTTOM &&
        item.tabName == TAB_ICONS_NAMES.JDROID &&
        item.visible
      ) {
        useIdeStore().setActiveTabPosition(TAB_BUTTON_POSITION.BOTTOM, TAB_ICONS_NAMES.JDROID)
      } else if (
        item.position == TAB_BUTTON_POSITION.RIGHT &&
        item.tabName == TAB_ICONS_NAMES.JDROID &&
        item.visible
      ) {
        useIdeStore().setActiveTabPosition(TAB_BUTTON_POSITION.RIGHT, TAB_ICONS_NAMES.JDROID)
      }
    })
  } else if (
    tabname == TAB_ICONS_NAMES.EXTERNAL_LIBRARIES &&
    currentView == IDETABSVIEW.ADVANCEIDE
  ) {
    values.forEach((item) => {
      if (
        item.position == TAB_BUTTON_POSITION.BOTTOM &&
        item.tabName == TAB_ICONS_NAMES.EXTERNAL_LIBRARIES &&
        !item.visible
      ) {
        useIdeStore().setActiveTabPosition(
          TAB_BUTTON_POSITION.BOTTOM,
          TAB_ICONS_NAMES.EXTERNAL_LIBRARIES
        )
      } else if (
        item.position == TAB_BUTTON_POSITION.RIGHT &&
        item.tabName == TAB_ICONS_NAMES.EXTERNAL_LIBRARIES &&
        !item.visible
      ) {
        useIdeStore().setActiveTabPosition(
          TAB_BUTTON_POSITION.RIGHT,
          TAB_ICONS_NAMES.EXTERNAL_LIBRARIES
        )
      }
    })
  } else if (tabname == TAB_ICONS_NAMES.JDROID && currentView == IDETABSVIEW.JDROIDCHAT) {
    values.forEach((item) => {
      if (item.position == TAB_BUTTON_POSITION.MAXIMIZE && item.tabName == TAB_ICONS_NAMES.JDROID) {
        useIdeStore().setActiveTabPosition(
          item.previousPosition as TAB_BUTTON_POSITION,
          TAB_ICONS_NAMES.JDROID
        )
      }
    })
  } else if (tabname == TAB_ICONS_NAMES.JDROID && currentView == IDETABSVIEW.JDROID) {
    if (useIdeStore().ideFullScreen || useIdeStore().isTabMaximized) {
      useIdeStore().ideFullScreen = false
      editorService.resizeOutputEditor()
      values.forEach((item) => {
        if (
          item.position == TAB_BUTTON_POSITION.BOTTOM &&
          item.tabName == TAB_ICONS_NAMES.JDROID &&
          !item.visible
        ) {
          useIdeStore().setActiveTabPosition(TAB_BUTTON_POSITION.BOTTOM, TAB_ICONS_NAMES.JDROID)
        } else if (
          item.position == TAB_BUTTON_POSITION.RIGHT &&
          item.tabName == TAB_ICONS_NAMES.JDROID &&
          !item.visible
        ) {
          useIdeStore().setActiveTabPosition(TAB_BUTTON_POSITION.RIGHT, TAB_ICONS_NAMES.JDROID)
        }
      })
    } else {
      values.forEach((item) => {
        const ioTabVisibleBottom =
          item.position == TAB_BUTTON_POSITION.BOTTOM &&
          item.tabName == TAB_ICONS_NAMES.IO &&
          item.visible

        const ioTabVisibleRight =
          item.position == TAB_BUTTON_POSITION.RIGHT &&
          item.tabName == TAB_ICONS_NAMES.IO &&
          item.visible
        const jdroidTabNotVisible = values.some(
          (i) => i.tabName == TAB_ICONS_NAMES.JDROID && !i.visible
        )
        const jdroidPosition = values.find((i) => i.tabName == TAB_ICONS_NAMES.JDROID)?.position

        if ((ioTabVisibleBottom || ioTabVisibleRight) && jdroidTabNotVisible) {
          useIdeStore().setActiveTabPosition(
            jdroidPosition as TAB_BUTTON_POSITION,
            TAB_ICONS_NAMES.JDROID,
            IDETABSVIEW.JDROIDCHAT
          )
        } else if (jdroidTabNotVisible) {
          useIdeStore().setActiveTabPosition(
            jdroidPosition as TAB_BUTTON_POSITION,
            TAB_ICONS_NAMES.JDROID,
            IDETABSVIEW.JDROIDCHAT
          )
        }
      })
    }
  } else if (tabname == TAB_ICONS_NAMES.BROWSER && currentView == IDETABSVIEW.IDE) {
    values.forEach((item) => {
      if (
        item.position == TAB_BUTTON_POSITION.BOTTOM &&
        item.tabName == TAB_ICONS_NAMES.BROWSER &&
        !item.visible
      ) {
        useIdeStore().setActiveTabPosition(TAB_BUTTON_POSITION.BOTTOM, TAB_ICONS_NAMES.BROWSER)
      } else if (
        item.position == TAB_BUTTON_POSITION.RIGHT &&
        item.tabName == TAB_ICONS_NAMES.BROWSER &&
        !item.visible
      ) {
        useIdeStore().setActiveTabPosition(TAB_BUTTON_POSITION.RIGHT, TAB_ICONS_NAMES.BROWSER)
      } else if (item.tabName == TAB_ICONS_NAMES.BROWSER && item.visible && closeTab) {
        useIdeStore().setActiveTabPosition(
          item.position as TAB_BUTTON_POSITION,
          TAB_ICONS_NAMES.BROWSER
        )
      }
    })
  } else if (tabname == TAB_ICONS_NAMES.BROWSER && currentView == IDETABSVIEW.HTML) {
    values.forEach((item) => {
      if (
        item.tabName != TAB_ICONS_NAMES.BROWSER &&
        item.tabName != TAB_ICONS_NAMES.JDROID &&
        item.visible
      ) {
        useIdeStore().setActiveTabPosition(
          item.position as TAB_BUTTON_POSITION,
          item.tabName as TAB_ICONS_NAMES
        )
      }
    })
  } else if (tabname == TAB_ICONS_NAMES.BROWSER && currentView == IDETABSVIEW.ADVANCEIDE) {
    values.forEach((item) => {
      if (item.tabName == TAB_ICONS_NAMES.BROWSER && !item.visible) {
        useIdeStore().setActiveTabPosition(
          item.position as TAB_BUTTON_POSITION,
          item.tabName as TAB_ICONS_NAMES
        )
      }
    })
  } else if (tabname == TAB_ICONS_NAMES.DB_TERMINAL && currentView == IDETABSVIEW.DB_TERMINAL) {
    values.forEach((item) => {
      if (item.tabName == TAB_ICONS_NAMES.DB_TERMINAL && !item.visible) {
        useIdeStore().setActiveTabPosition(
          item.position as TAB_BUTTON_POSITION,
          item.tabName as TAB_ICONS_NAMES
        )
      }
    })
  }
}

export default {
  updateTabVisiblity
}
